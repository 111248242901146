import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import CtaTelWpp from "../../components/CTATelWpp";
import FormasPagamento from "../../components/FormasPagamento";

import about1_1 from "../../assets/about1_1_edit.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about2 from "../../assets/about2.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">Produtos e serviços</h2>

          <p className="text">
            Produtos com tecnologia avançada, os melhores do mercado estético,
            com escolhas personalizadas para cada tipo de pele e queixas
            principais, visando melhor protocolo de tratamento.
          </p>

          <p className="text text2">Acompanhe Nossos Resultados:</p>

          <div className="gallery">
            <div className="item">
              <figure>
                <img src={about1_1} alt="Imagem 1" />
              </figure>

              <p className="text">
                Procedimento de aplicação de Botox facial, visando uma aparência
                harmoniosa e rejuvenescida, elevando a autoestima e confiança da
                paciente.
              </p>

              <CtaWpp text={"Eu quero!"} />
            </div>

            <div className="item">
              <figure>
                <img src={about1_2} alt="Imagem 2" />
              </figure>

              <p className="text">
                Procedimento de harmonização facial com Rinomodelação. Técnica
                aplicada com naturalidade eficiência, visando o conforto e
                satisfação da paciente.
              </p>

              <CtaWpp text={"Eu quero!"} />
            </div>

            <div className="item">
              <figure>
                <img src={about1_3} alt="Imagem 3" />
              </figure>

              <p className="text">
                Preenchimento labial. Dando um aspecto de rejuvenescimento
                labial, com uma aparência mais jovial e saudável à paciente.
              </p>

              <CtaWpp text={"Eu quero!"} />
            </div>

            <div className="item">
              <figure>
                <img src={about1_4} alt="Imagem 4" />
              </figure>

              <p className="text">
                Aplicação de toxina botulínica para conseguir um resultado de
                maior elasticidade da pele, amenizando em quase 100% as marcas
                de expressões.
              </p>

              <CtaWpp text={"Eu quero!"} />
            </div>
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <div className="left">
            <h2 className="title">SOBRE MIM</h2>

            <p className="subtitle">Dra. Priscila Pacheco</p>

            <p className="text">
              Sou Priscila, tenho 45 anos, carioca, bacharel enfermagem e como
              profissional da saúde, atuei por 25 anos em vários unidades
              hospitalares no Rio de Janeiro, até me encontrar na área da
              estética onde me identifico e sinto um imenso prazer em cuidar do
              maior órgão do corpo humano.
            </p>

            <p className="text">
              Possuo o título de Especialização e Pós-graduação em Estética
              Avançada e Cosmetologia. Especialista em harmonização facial e
              corporal, atuando há 5 anos com diversos procedimento de alta
              tecnologia e produtos de alta qualidade.
            </p>

            <p className="text">
              Meu desejo hoje é poder dedicar a maior parte do meu tempo
              realizando o sonho de cada paciente que acredita que podemos
              envelhecer com saúde e naturalidade, priorizando a beleza Natural
              em Evidência. Buscando sempre entregar e resultados reais para uma
              pele radiante, alcançando os objetivos com segurança e eficiência.
            </p>

            <p className="text">
              Atendo no meu espaço, em um ambiente aconchegante, mantendo sempre
              um atendimento humanizado e personalizado, garantindo assim o
              conforto e segurança dos pacientes. Dedicando a minha vida
              profissional na estética, ainda na teoria , fazendo o bem sem
              olhar a quem.
            </p>
          </div>

          <figure className="right">
            <img src={about2} alt="Dra Priscila Pacheco" />
          </figure>
        </div>
      </article>

      <article className="about3">
        <div className="container vertical">
          <h2 className="title">especialidades:</h2>

          <div className="gallery">
            <div className="item">
              <p className="item_title">Shikbooster</p>
            </div>
            <div className="item">
              <p className="item_title">Microagulhamento</p>
            </div>
            <div className="item">
              <p className="item_title">Harmonização Facial</p>
              <p className="item_text">Full Face</p>
            </div>
            <div className="item">
              <p className="item_title">Toxina Botulínica</p>
              <p className="item_text">Full Face</p>
            </div>
            <div className="item">
              <p className="item_title">Rinomodelação</p>
            </div>
            <div className="item">
              <p className="item_title">Fios de PDO</p>
            </div>
            <div className="item">
              <p className="item_title">Bioestimuladores de Colágeno</p>
              <p className="item_text">
                Facial e Corporal
                <span>
                  (Radiesse, Sculptra, Elleva, Ellanse e Jato de Plasma).
                </span>
              </p>
            </div>
            <div className="item">
              <p className="item_title">Jato de Plasma</p>
            </div>
            <div className="item">
              <p className="item_title">Terapia Capilar</p>
            </div>
            <div className="item">
              <p className="item_title">Lipoenzimática Melasma</p>
            </div>
            <div className="item">
              <p className="item_title">PDRN</p>
            </div>
            <div className="item">
              <p className="item_title">Exossomos</p>
            </div>
            <div className="item">
              <p className="item_title">PRP Facial e Corporal</p>
            </div>
            <div className="item">
              <p className="item_title">Soroterapia com Injetáveis</p>
            </div>
          </div>

          <CtaWpp />
        </div>
      </article>

      <article className="about4">
        <div className="container vertical">
          <h2 className="title">REDUZA OS EFEITOS DO TEMPO</h2>

          <p className="text">
            Fale comigo para fazermos uma avaliação. Eleve sua autoestima,
            aprimorando sua beleza de forma saudável, com resultados mais
            naturais possíveis.
          </p>

          <p className="subtitle">
            Fale comigo agora mesmo, por Telefone ou WhatsApp:
          </p>

          <CtaTelWpp />

          <p className="text final">
            **Procedimentos somente realizados após avaliação clínica de saúde
            com a profissional. Avaliação online. Ou presencial.
          </p>
        </div>
      </article>

      <FormasPagamento />
    </section>
  );
};

export default About;
